import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/packages/frontend-usagers/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_PKoA8z3kQ4 from "/app/node_modules/@socialgouv/dsfr-toaster-nuxt-module/dist/runtime/plugin.js";
import init_user_IUCg4WLHqu from "/app/packages/frontend-usagers/src/plugins/init-user.js";
import logger_53dicuXaVa from "/app/packages/frontend-usagers/src/plugins/logger.js";
import ofetch_BCdZC2GfCN from "/app/packages/frontend-usagers/src/plugins/ofetch.js";
import sentry_client_hvfEHScgur from "/app/packages/frontend-usagers/src/plugins/sentry.client.js";
import vue_matomo_GvlXODNrTN from "/app/packages/frontend-usagers/src/plugins/vue-matomo.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_PKoA8z3kQ4,
  init_user_IUCg4WLHqu,
  logger_53dicuXaVa,
  ofetch_BCdZC2GfCN,
  sentry_client_hvfEHScgur,
  vue_matomo_GvlXODNrTN
]